<template>
  <v-container fluid style="background-color:#f2f2f2 !important;min-height:100%;">
    <v-row>
      <v-col cols="12">
        <v-card flat style="border-radius:8px;min-height:320px;">
          <v-card-title
            class="py-2 px-2"
            dark
            style="color:teal;border-bottom: 1px solid teal;"
          >
            <v-btn
              color="teal"
              dark
              class="text-none mr-2 mb-2"
              @click="showCreateProgram"
            >
              <v-icon small class="mr-2">mdi-plus</v-icon>
              Thêm chương trình
            </v-btn>
            <template>
              <v-btn
                color="red"
                light style="color:white;"
                :disabled='!allowEndProgram'
                class="text-none mr-2 mb-2"
                @click="endPrograms"
              >
                <v-icon small class="mr-2">mdi-delete-forever</v-icon>
                Kết thúc
              </v-btn>

              <v-btn v-if="selected_programs && selected_programs.length > 0"
                color="red"
                dark
                class="text-none mr-2 mb-2"
                @click="deletePrograms"
              >
                <v-icon small class="mr-2">mdi-delete-forever</v-icon>
                Xóa
              </v-btn>
            </template>
            <v-spacer />
            <v-text-field
              class="mb-2"
              v-model="searchString"
              append-icon="mdi-magnify"
              label="Search"
              clearable outlined dense
              single-line hide-details
              :style = "{'width': $vuetify.breakpoint.smAndDown?'100%':'220px'}"
            ></v-text-field>
          </v-card-title>

          <v-card-text class="pa-0 pt-2" style="min-height:300px;">
            <v-data-table
              flat
              v-model="selected_programs"
              :headers="headers"
              :items="programs"
              :loading="loading"
              loading-text="Loading... Please wait"
              no-data-text="Chưa có chương trình"
              item-key="_id"
              :mobile-breakpoint="0"
              :items-per-page="pageSize"
              show-select
              class="elevation-0"
              :search="searchString"
              :page.sync="programPage"
              @page-count="pageCount = $event"
              hide-default-footer
            >
              <template v-slot:item.name="{ item }">
                <div @click="gotoPath('/programs-detail/' + item._id)"
                  style="font-size:15px;cursor:pointer;color:#666666;">
                  <b><u>{{ item.name }}</u></b>
                </div>
              </template>
              <template v-slot:item.status="{ item }">
                <span 
                  :style="{'color': item.status=='END'?'red':'green'}"
                  style="font-size:15px; font-weight:500;"> 
                  {{statusResolve(item)}}
                </span>
              </template>
              <!--
              <template v-slot:item.enable="{ item }">
                <v-icon v-if="!item.enable">
                  mdi-lock
                </v-icon>
              </template>
              -->
            </v-data-table>
            <div v-if="pageCount > 1" class="text-center pt-2">
              <v-pagination
                v-model="programPage"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      token: "token",
    }),
    allowEndProgram () {
       if(this.selected_programs.length<=0){
          return false
       }
       let allowEnd = false
       this.selected_programs.forEach(pro => {
          if (pro.status !='END') allowEnd = true
       })
       return allowEnd
    }
  },
  data() {
    return {
      roles: ['program-manager', 'admin'],
      programs: [],
      selected_programs: [],
      pageSize: 15,
      pageCount: 0,
      programPage: 1,
      loading: false,
      searchString: "",
      headers: [
        {
          text: "Tên chương trình",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Trạng thái",
          align: "start",
          sortable: true,
          value: "status",
        },
        
      ],
    };
  },
  methods: {
    showCreateProgram() {
      this.$router.push({path: '/programs-detail/0'})
    },
    gotoPath (path) {
       this.$router.push({path: path})
    },
    statusResolve(program) {
      if(program.status == 'RUNNING') {
         return "Đang quay"
      }
      if(program.status == 'END') {
         return "Đã kết thúc"
      }
      return "Chưa xác định"
    },
    fetchPrograms() {
      let self = this
      self.loading = true
      self.selected_programs = []
      let paramsprograms = {
        // show_deleted: true
      };
      self.axios
        .get(self.$root.apiAssetMana + "/programs", {
          headers: { "x-auth": this.token },
          params: paramsprograms,
        })
        // + 'owners='+self.$root.site_id
        .then((res) => {
          if (res.data.status == "OK") {
            // console.log(res.data.content)
            self.programs = res.data.content
            // self.programs = self.programs.filter(i => i.type == 'car')
            // self.programs = res.data.content;
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          self.loading = false
        });
    },
    endPrograms() {
      if (confirm("Bạn có chắc muốn dừng các mục đã chọn ?")) {
        if (!this.selected_programs || this.selected_programs.length <= 0)
          return;
        let self = this;
        this.axios
          .put(
            self.$root.apiAssetMana 
            + `/programs/end/${this.selected_programs.map((u) => u._id).join(';')}`,
            {
              headers: { "x-auth": this.token },
            }
          )
          .then((res) => {
            if (res.data.status == "OK") {
              alert("Cập nhật thành công!")
              self.fetchPrograms()
            } else {
              console.log(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          })
      }
      // console.log(this.selected_programs)
    },
    deletePrograms() {
      if (confirm("Bạn có chắc muốn xóa các mục đã chọn ?")) {
        if (!this.selected_programs || this.selected_programs.length <= 0)
          return;
        let self = this;
        this.axios
          .delete(
            self.$root.apiAssetMana 
            + `/programs/${this.selected_programs.map((u) => u._id).join(';')}`,
            {
              headers: { "x-auth": this.token },
            }
          )
          .then((res) => {
            if (res.data.status == "OK") {
              alert("Xóa thành công!")
              self.fetchPrograms()
            } else {
              console.log(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          })
      }
      // console.log(this.selected_programs)
    },
  },
  created() {
   //  if (this.$vuetify.breakpoint.smAndDown){
   //    this.headers = this.headers.filter(h => h.value!='type')
   //  }
    this.$nextTick(() => {
      this.fetchPrograms()
    });
  },
};
</script>

<style></style>
